import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import {
  Box,
  Button,
  Divider,
  Text,
  Select,
  Input,
  FormControl,
  FormLabel,
  Container,
  InputLeftAddon,
  InputGroup,
  Image,
  VStack,
  Link,
  Heading,
  CircularProgress,
  Center,
  useToast,
  NumberInput,
} from "@chakra-ui/react";

import defaultTradition from "../images/trad-2025.png";
import sepIraFeb2022 from "../images/sep-2025.png";
import solo401k from "../images/solo-2025.png";
import logo from "../images/logo.png";
import useAppStore from "../store/app.store";

const country = "US";

const Form = () => {
  const [contributionMethod, setContributionMethod] = useState("");
  const [contributionMethodType, setContributionMethodType] = useState("");
  const [accountOwnerDOB, setAccountOwnerDOB] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zIP, setZIP] = useState("");
  const [directedIRAAccountNumber, setDirectedIRAAccountNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [customRoutingNumber, setCustomRoutingNumber] = useState("");
  const [customAccountNumber, setCustomAccountNumber] = useState("");
  const [allAccountNumbers, setAllAccountNumbers] = useState([]);
  const [rawAccountNumbers, setRawAccountNumbers] = useState([]);
  const [accountType, setAccountType] = useState("");
  const [contributionYear, setContributionYear] = useState("");
  const [contributionType, setContributionType] = useState("");
  const [amount, setAmount] = useState(10);
  const [flag, setFlag] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [customField, setCustomField] = useState(false);
  const toast = useToast();
  const { accessToken } = useAppStore();

  useEffect(() => {
    getData();
  }, [accessToken]);

  const getData = async () => {
    const promises = [];
    promises.push(
      axios.post(`${process.env.REACT_APP_BASE_URL}/plaid/identity`, {
        access_token: accessToken,
      })
    );
    promises.push(
      axios.post(`${process.env.REACT_APP_BASE_URL}/plaid/auth`, {
        access_token: accessToken,
      })
    );
    Promise.all(promises)
      .then((responses) => {
        const identity = responses[0].data.accounts;
        if (identity.length > 0) {
          let userName = identity[0]?.owners?.[0]?.names || "";
          userName = userName.toString();
          const [fName, , lName] = userName.split(" ");
          setFirstName(fName);
          setLastName(lName);
          setPhone(identity[0]?.owners?.[0]?.phone_numbers?.[0]?.data || "");
          setEmail(identity[0]?.owners?.[0]?.emails?.[0]?.data || "");
          setAddress1(
            identity[0]?.owners?.[0]?.addresses?.[0]?.data?.street || ""
          );
          setCity(identity[0]?.owners?.[0]?.addresses?.[0]?.data?.city || "");
          setState(
            identity[0]?.owners?.[0]?.addresses?.[0]?.data?.region || ""
          );
          setZIP(
            identity[0]?.owners?.[0]?.addresses?.[0]?.data?.postal_code || ""
          );
        }
        if (responses[1].data?.numbers?.ach?.length > 0) {
          setAllAccountNumbers(responses[1].data.numbers.ach);
          setRawAccountNumbers(identity);
        } else {
          setAllAccountNumbers([]);
          setRawAccountNumbers([]);
        }
        Sentry.captureMessage(responses);
      })
      .catch((error) => {
        alert("Failed to fetch information from plaid identity");
        Sentry.captureException(error);
        setAllAccountNumbers([]);
        setRawAccountNumbers([]);
        return;
      });
  };

  const changeContributonMethod = (e) => {
    setContributionMethod(e.target.value);
    setShowImage(true);
    if (e.target.value === "3") {
      setFlag(true);
      setContributionMethodType("Solo 401(K) Contribution");
    } else {
      setFlag(false);
      if (e.target.value === "2") {
        setContributionMethodType("SEP IRA");
      } else {
        setContributionMethodType(
          "Traditional IRA, Roth IRA, HSA, Coverdell ESA"
        );
        setContributionYear("");
      }
    }
  };

  const sendData = (e) => {
    e.preventDefault();
    if (directedIRAAccountNumber.length < 7) {
      toast({
        title: "Invalid Directed IRA Account Number",
        description: "Please enter a valid Directed IRA account number",
        status: "error",
      });
      return;
    }
    setDisableSubmit(true);
    const url = `${process.env.REACT_APP_BASE_URL}/cybersource/process-payment`;
    const data = {
      dateOfBirth: accountOwnerDOB,
      contributionMethodType: contributionMethodType,
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      email: email,
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      ZIP: zIP,
      directedIRAAccountNumber: directedIRAAccountNumber,
      routingNumber:
        allAccountNumbers.length === 0 || accountNumber === "custom"
          ? customRoutingNumber
          : routingNumber,
      accountNumber:
        allAccountNumbers.length === 0 || accountNumber === "custom"
          ? customAccountNumber
          : accountNumber,
      accountType: accountType,
      contributionYear: contributionYear,
      contributionType: contributionType,
      amount: amount,
      country: country,
    };
    axios
      .post(url, data)
      .then((res) => {
        if (res?.data?.id === undefined) {
          Sentry.captureException(res);
          toast({
            title: "Failed to process payment",
            description:
              "If you see this message again please contact support.",
            status: "error",
          });
        } else {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/zapier/send-data-to-hook`,
              {
                firstName: firstName,
                lastName: lastName,
                email: email,
                url: `/pdf/${res?.data?.id}.pdf`,
                amount: amount,
                accountNumber: directedIRAAccountNumber,
                accountType: "checking",
              }
            )
            .then((res) => {
              window.location.href =
                "https://directedira.com/contribution-thankyou/";
            })
            .catch((err) => {
              window.location.href =
                "https://directedira.com/contribution-thankyou/";
            });
        }
      })
      .catch((err) => {
        toast({
          title: "Failed to process payment",
          description: "If you see this message again please contact support.",
          status: "error",
        });
      })
      .finally(() => {
        setDisableSubmit(false);
      });
  };

  const maskingAccountNumber = (number) => {
    let temp = number.replace(
      /\b[\dX][-. \dX]+(\d{4})\b/g,
      function (match, capture) {
        return Array(match.length - 4).join("*") + capture;
      }
    );
    return temp;
  };

  return (
    <Box w={"100%"} h={"100vh"} paddingBottom={"20px"} overflow={"scroll"}>
      <Image
        src={logo}
        maxWidth="250px"
        minWidth="100px"
        style={{ paddingLeft: "20px", paddingTop: "20px" }}
      />
      <VStack>
        <Text fontWeight="600" fontSize="2rem">
          Make a Contribution
        </Text>
      </VStack>

      {disableSubmit ? (
        <Container maxW="50%" mt="8vh">
          <Center height="90vh">
            <VStack>
              <Image src={logo} size="xs" />
              <CircularProgress isIndeterminate thickness="4px" />
            </VStack>
          </Center>
        </Container>
      ) : (
        <Container
          maxW="60%"
          mt={4}
          border="1px solid #e4e4e4"
          borderRadius="6px"
          py={4}
        >
          <form onSubmit={sendData}>
            <Text fontSize="2xl">Directed IRA Account Information</Text>

            <FormControl>
              <FormLabel>Account Owner First Name</FormLabel>
              <Input
                isRequired={true}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Account Owner First Name"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Account Owner Last Name</FormLabel>
              <Input
                isRequired={true}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Billing Address 1</FormLabel>
              <Input
                isRequired={true}
                value={address1}
                onChange={(e) => setAddress1(e.target.value)}
                placeholder="Address 1"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Billing Address 2</FormLabel>
              <Input
                isRequired={false}
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
                placeholder="Address 2"
              />
            </FormControl>
            <FormControl>
              <FormLabel>City</FormLabel>
              <Input
                isRequired={true}
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder="City"
              />
            </FormControl>
            <FormControl>
              <FormLabel>State</FormLabel>
              <Input
                isRequired={true}
                value={state}
                onChange={(e) => setState(e.target.value)}
                placeholder="State"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Zip Code</FormLabel>
              <Input
                isRequired={true}
                value={zIP}
                onChange={(e) => setZIP(e.target.value)}
                placeholder="Zip code"
              />
            </FormControl>
            <FormControl mt={2}>
              <FormLabel>Date of Birth</FormLabel>
              <Input
                type="date"
                isRequired={true}
                value={accountOwnerDOB}
                onChange={(e) => setAccountOwnerDOB(e.target.value)}
                placeholder="Date of Birth"
              />
            </FormControl>
            <FormLabel mt={4}>Directed IRA Account Number</FormLabel>
            <FormControl>
              <NumberInput isRequired={true}>
                <Input
                  type="number"
                  maxLength={7}
                  minLength={7}
                  value={directedIRAAccountNumber}
                  onChange={(e) => {
                    if (e.target.value.length <= 7) {
                      setDirectedIRAAccountNumber(e.target.value);
                    }
                  }}
                  placeholder="Directed IRA Account Number"
                />
              </NumberInput>
            </FormControl>
            <Divider mt={4} />

            <Text fontSize="2xl" mt={4}>
              Contribution Information
            </Text>
            <FormLabel htmlFor="email">Account Type</FormLabel>
            <FormControl>
              <Select
                isRequired={true}
                mb={3}
                placeholder="Account Type"
                onChange={changeContributonMethod}
                value={contributionMethod}
              >
                <option value="1">
                  Traditional IRA, Roth IRA, HSA, Coverdell ESA
                </option>
                <option value="2">SEP IRA</option>
                <option value="3">Solo 401(K) Contribution</option>
              </Select>
            </FormControl>
            {!showImage ? null : (
              <Image
                mr="auto"
                ml="auto"
                src={
                  contributionMethod === "3"
                    ? solo401k
                    : contributionMethod === "2"
                    ? sepIraFeb2022
                    : defaultTradition
                }
              />
            )}
            <Divider mt={4} />
            <Box hidden={!flag}>
              <FormLabel mt={4}>Contribution Type</FormLabel>
              <FormControl>
                <Select
                  isRequired={flag ? true : false}
                  value={contributionType}
                  placeholder="Contribution Type"
                  onChange={(e) => setContributionType(e.target.value)}
                >
                  <option value="Employee Contribution">
                    Employee Contribution
                  </option>
                  <option value="Employer Contribution">
                    Employer Contribution
                  </option>
                </Select>
              </FormControl>
              <Divider mt={4} />
            </Box>
            <FormControl mt={4}>
              <FormLabel>Contribution Year</FormLabel>
              <Select
                isRequired={true}
                value={contributionYear}
                placeholder="Contribution Year"
                onChange={(e) => setContributionYear(e.target.value)}
              >
                {/* {contributionMethodType !==
                  "Traditional IRA, Roth IRA, HSA, Coverdell ESA" && (
                  <option value="2023">2023</option>
                )} */}
                <option value="2024">2024</option>
                <option value="2025">2025</option>
              </Select>
            </FormControl>
            <FormLabel mt={4}>Contribution Amount</FormLabel>
            <FormControl>
              <InputGroup>
                <InputLeftAddon children="$" />
                <Input
                  isRequired={true}
                  type="number"
                  value={amount}
                  placeholder="Amount"
                  onChange={(e) => setAmount(e.target.value)}
                />
              </InputGroup>
            </FormControl>
            {allAccountNumbers.length > 0 ? (
              <FormControl>
                <FormLabel mt={4}>Bank Account Number</FormLabel>
                <Select
                  isDisabled={allAccountNumbers.length === 0 ? true : false}
                  isRequired={true}
                  value={accountNumber}
                  onChange={(e) => {
                    if (e.target.value === "custom") {
                      setAccountNumber("custom");
                      setCustomField(true);
                    } else {
                      const accountDetails = allAccountNumbers.find(
                        (x) => x.account === e.target.value
                      );
                      const rawAccountNumber = rawAccountNumbers.find(
                        (x) => x.account_id === accountDetails.account_id
                      );
                      setRoutingNumber(accountDetails.routing);
                      setAccountNumber(accountDetails.account);
                      setAccountType(
                        rawAccountNumber.subtype === "checking" ? "C" : "S"
                      );
                      setCustomField(false);
                    }
                  }}
                  placeholder="Select Account Number"
                >
                  {allAccountNumbers.map((accNumber) => {
                    return (
                      <option key={accNumber.account} value={accNumber.account}>
                        {maskingAccountNumber(accNumber.account)}
                      </option>
                    );
                  })}
                  <option value={"custom"}>Custom</option>
                </Select>
              </FormControl>
            ) : (
              <>
                <FormLabel mt={4}>Bank Account Number</FormLabel>
                <FormControl>
                  <InputGroup>
                    <InputLeftAddon children="$" />
                    <Input
                      isRequired={true}
                      type="number"
                      value={customAccountNumber}
                      placeholder="Enter Account number"
                      onChange={(e) => setCustomAccountNumber(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>
                <FormLabel mt={4}>Routing Number</FormLabel>
                <FormControl>
                  <InputGroup>
                    <InputLeftAddon children="$" />
                    <Input
                      isRequired={true}
                      type="number"
                      value={customRoutingNumber}
                      placeholder="Enter Routing Number"
                      onChange={(e) => setCustomRoutingNumber(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>
                <FormLabel mt={4}>Account Type</FormLabel>
                <FormControl>
                  <Select
                    isRequired={true}
                    mb={3}
                    placeholder="Account Type"
                    onChange={(e) => setAccountType(e.target.value)}
                    value={accountType}
                  >
                    <option value="C">Checking</option>
                    <option value="S">Saving</option>
                  </Select>
                </FormControl>
              </>
            )}

            {customField && allAccountNumbers.length > 0 && (
              <>
                <FormLabel mt={4}>Bank Account Number</FormLabel>
                <FormControl>
                  <Input
                    isRequired={true}
                    type="number"
                    value={customAccountNumber}
                    placeholder="Enter Account number"
                    onChange={(e) => setCustomAccountNumber(e.target.value)}
                  />
                </FormControl>
                <FormLabel mt={4}>Routing Number</FormLabel>
                <FormControl>
                  <Input
                    isRequired={true}
                    type="number"
                    value={customRoutingNumber}
                    placeholder="Enter Routing Number"
                    onChange={(e) => setCustomRoutingNumber(e.target.value)}
                  />
                </FormControl>
                <FormLabel mt={4}>Account Type</FormLabel>
                <FormControl>
                  <Select
                    isRequired={true}
                    mb={3}
                    placeholder="Account Type"
                    onChange={(e) => setAccountType(e.target.value)}
                    value={accountType}
                  >
                    <option value="C">Checking</option>
                    <option value="S">Saving</option>
                  </Select>
                </FormControl>
              </>
            )}
            {customField && allAccountNumbers.length > 0 && (
              <>
                <FormLabel mt={4}>Bank Account Number</FormLabel>
                <FormControl>
                  <Input
                    isRequired={true}
                    type="number"
                    value={customAccountNumber}
                    placeholder="Enter Account number"
                    onChange={(e) => setCustomAccountNumber(e.target.value)}
                  />
                </FormControl>
                <FormLabel mt={4}>Routing Number</FormLabel>
                <FormControl>
                  <Input
                    isRequired={true}
                    type="number"
                    value={customRoutingNumber}
                    placeholder="Enter Routing Number"
                    onChange={(e) => setCustomRoutingNumber(e.target.value)}
                  />
                </FormControl>
                <FormLabel mt={4}>Account Type</FormLabel>
                <FormControl>
                  <Select
                    isRequired={true}
                    mb={3}
                    placeholder="Account Type"
                    onChange={setAccountType}
                    value={accountType}
                  >
                    <option value="C">Checking</option>
                    <option value="S">Saving</option>
                  </Select>
                </FormControl>
              </>
            )}

            <Divider mt={4} />

            <FormControl>
              <FormLabel mt={4}>
                Where would you like your reciept emailed? (Please use your
                email address on file with DirectedIRA*)
              </FormLabel>
              <Input
                isRequired={true}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={email}
              />
            </FormControl>
            <Heading size="sm" mt={4}>
              All contributions made through this site will be processed via
              ACH. <mark>Please allow 5 business days for your funds to be available
              in your account.</mark> The processing period depends on your bank, some
              banks take longer to deliver funds. Directed IRA will notify you
              via email once your funds are available. For expedited processing,
              you must initiate a wire and complete the "Contribution Form" by
              clicking
              <Link
                color="#ee3e24"
                href="https://directedira.com/new-contribution-esign"
                target="_blank"
                rel="noopener"
              >
                {" HERE"}
              </Link>
              .
            </Heading>
            <br />
            <Button
              _hover={{ bg: "#ee3924", color: "white" }}
              type="submit"
              isDisabled={disableSubmit ? true : false}
            >
              Submit
            </Button>
          </form>
        </Container>
      )}
    </Box>
  );
};

export default Form;
